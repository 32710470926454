import React, { useEffect } from "react"
import Container from "../../microcomponents/container/container"
import "./headerv2.scss"
import {
  ArrowIcon,
  ChevronIcon,
  FullServiceTeamIcon,
  OverViewIcon,
  PredictionEngineIcon,
  ProcessIcon,
  ResultIcon,
  SPZLOGOWHITE,
} from "../../microcomponents/icons"

import { Link, navigate } from "gatsby"
import Button from "../../microcomponents/button/button"
export default function HeaderV2({
  version = "v1",

  ctaIcon = <ArrowIcon />,
}) {
  const [navOpen, setNavOpen] = React.useState(false)
  const handleScroll = () => {
    if (window.scrollY < 30) {
      if (document.querySelector(".header-wrapper")) {
        document.querySelector(".header-wrapper").classList.remove("sticky")
      }
    } else {
      if (document.querySelector(".header-wrapper")) {
        document.querySelector(".header-wrapper").classList.add("sticky")
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, false)
    return () => window.removeEventListener("scroll", handleScroll, false)
  }, [])

  const HowItWorksSubMenu = () => {
    const submenuitems = [
      {
        title: "Overview",
        description: "Get 3X more wins, by using predictive CRO.",
        link: "/how-it-works/",
        icon: <OverViewIcon />,
      },
      {
        title: "Prediction Engine",
        description: "Scraping A/B tests to find proven winning patterns.",
        link: "/prediction-engine/",
        icon: <PredictionEngineIcon />,
      },
      {
        title: "Full Service Team",
        description:
          "Everything done for your model.  Design, research, copy, & dev.",
        link: "/full-service-team/",
        icon: <FullServiceTeamIcon />,
      },
      {
        title: "Process",
        description:
          "Get fast, consistent results with an evidence based roadmap.",
        link: "/process/",
        icon: <ProcessIcon />,
      },
      {
        title: "Results",
        description: "Get 30% in your first 90 days. Guaranteed.",
        link: "/results/",
        icon: <ResultIcon />,
      },
    ]
    return (
      <div className="submenu-wrapper">
        <div className="submenu-inner">
          <div className="submenu-items">
            {submenuitems.map((item, index) => {
              return (
                <div key={index} className="submenu-item">
                  <Link to={item.link} activeClassName="active-link">
                    <div className="submenu-icon">{item.icon}</div>
                    <div className="submenu-copy">
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  const HeaderLinks = ({ className }) => {
    return (
      <div className={`header-links ${className}`}>
        <ul className={"nav nav-list"}>
          <li
            className=" nav-list-item howitworks submenu"
            onMouseLeave={e => {
              if (window.matchMedia("(min-width: 1361px)").matches) {
                e.currentTarget.classList.remove("open")
              }
            }}
            onClick={e => {
              if (window.matchMedia("(max-width: 1360px)").matches) {
                e.currentTarget.classList.toggle("open")
              }
              // if (!e.currentTarget.classList.contains("open")) {
              //   e.currentTarget.classList.add("open")
              // }
            }}
          >
            <a
              className="nav-link"
              onMouseOver={e => {
                if (window.matchMedia("(min-width: 1361px)").matches) {
                  e.currentTarget.closest("li").classList.add("open")
                }
              }}
            >
              How It Works <ChevronIcon />
            </a>
            <HowItWorksSubMenu />
          </li>
          <li className=" nav-list-item cutomers">
            <Link
              to="/clients/"
              className="nav-link"
              activeClassName="active-link"
            >
              Clients
            </Link>
          </li>
          <li className=" nav-list-item testimonials ">
            <Link
              to="/testimonials/"
              className="nav-link"
              activeClassName="active-link"
            >
              Testimonials
            </Link>
          </li>
          <li className=" nav-list-item pricing ">
            <Link
              to="/pricing/"
              className="nav-link"
              activeClassName="active-link"
            >
              Pricing
            </Link>
          </li>
          <li className=" nav-list-item pricing ">
            <Link
              to="/about-us/"
              className="nav-link"
              activeClassName="active-link"
            >
              About
            </Link>
          </li>

          {/* <li className=" nav-list-item aboutus ">
            <Link
              to="/full-service-team"
              className="nav-link"
              activeClassName="active-link"
            >
              Full Service Team
            </Link>
          </li> */}
        </ul>
      </div>
    )
  }
  const HeaderCta = () => {
    return (
      <div className="header-cta">
        <Button
          className="btn-component nav-link get-demo"
          type={`cta`}
          version={version}
          // href={`/get-demo`}
          onClick={e => {
            const params = new URLSearchParams(window.location.search)
            let url = `/get-demo/`
            if (params.size > 0) {
              url = `${url}?${params.toString()}`
            }
            navigate(url)
          }}
          icon={ctaIcon}
        >
          Get a Demo
        </Button>

        {/* <Link to="/get-demo" className="nav-link get-demo">
          Get a Demo{" "}
          {version === "v2" && (
            <span className="icon">
              <span className="left">
                <ArrowIcon />
              </span>
              <span className="right">
                {" "}
                <ArrowIcon />
              </span>
            </span>
          )}
        </Link> */}
      </div>
    )
  }

  const toggleMobileMenu = () => {
    document.querySelector(".header-wrapper").classList.toggle("open")
  }

  return (
    <Container className={`header-wrapper ${version}`}>
      <div className="header">
        <Link to={`/`} className="header-logo">
          <SPZLOGOWHITE />
        </Link>
        <HeaderLinks className="desktop" navOpen={navOpen} />

        <HeaderCta />
        <div
          className="headermenuandtext hidedesktop"
          onClick={() => {
            toggleMobileMenu()
          }}
        >
          <span> Menu</span>
          <div className="nav-toggle">
            <div className="nav-toggle-bar"></div>
            <div className="nav-toggle-bar"></div>
            <div className="nav-toggle-bar"></div>
          </div>
        </div>
      </div>
      <div className="mobile-menu-wrapper">
        <div className="mobile-menu-inner">
          <HeaderLinks className="mobile" />
        </div>
      </div>
      <div className="mobile-sticky-cta-wrapper">
        <div className="mobile-sticky-cta-inner">
          <div>
            <h3>See it in action</h3>
            <p>See the top 3 recommendations for your site.</p>
          </div>
          <div>
            <Button type={`cta`} version={`v2`} href={`/get-demo/`}>
              Get a Demo
            </Button>
          </div>
        </div>
      </div>
    </Container>
  )
}
