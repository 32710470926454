import React, { useMemo, useState } from "react"
import "./toppredictions.scss"
import Container from "../container/container"
import HeadSubhead from "../headsubhead/headsubhead"
import Image from "../../components/image-component/image-2023"
import ProgressBar from "../progressbar/progressbar"
import Button from "../button/button"
import { InputErrorIcon, InputValidIcon } from "../icons"
export default function TopPredictions({
  version = "v1",
  heading = "Get THE TOP 3 predictions<br class='hidetablet hidemobile'/> for your site",
  subheading = "See the top 3 CRO recommendations from our prediction engine<br class='hidemobile'/> for your most important page when you book a demo.",
  icon = "",
  placeholder = "Email address",
  wrapperClass = "pd-112-top",
}) {
  const [error, setError] = useState("")

  const showErrorMessage = () => {
    setError("Please enter a valid email")
    setTimeout(() => {
      setError("")
    }, 3000)
  }

  const handleInputStates = (e, type) => {
    if (type === "focus") {
      e.target.closest("div").classList.add("float-labels")
      e.target.closest("div").classList.add("focus")
    }
    if (type === "blur" && e.target.value.trim() === "") {
      e.target.closest("div").classList.remove("float-labels")
    }
    if (type === "blur") {
      e.target.closest("div").classList.remove("focus")
    }
  }

  const allValid = closestInputWrap => {
    closestInputWrap.classList.remove("error")
    closestInputWrap.classList.remove("focus")
    closestInputWrap.classList.add("valid")
  }

  const allError = closestInputWrap => {
    closestInputWrap.classList.add("error")
    closestInputWrap.classList.remove("focus")
    closestInputWrap.classList.remove("valid")
  }

  const handleBlur = (closestInputWrap, condition) => {
    if (!condition) {
      allError(closestInputWrap)
      return
    } else {
      allValid(closestInputWrap)
      return true
    }
  }

  return (
    <Container className={`top-pred-wrapper ${wrapperClass} ${version}`}>
      <HeadSubhead
        version={version}
        heading={heading}
        subheading={subheading}
      ></HeadSubhead>
      <div className="prediction-form-wrapper">
        <div className="prediction-form-inner">
          <div className="prediction-form">
            <form
              onSubmit={e => {
                e.preventDefault()
                const { getdemo } = e.target.elements
                var closestInputWrap = document
                  .querySelector(`[name="getdemo"]`)
                  .closest(".input-wrap")
                if (
                  !handleBlur(closestInputWrap, getdemo.value.trim() !== "")
                ) {
                  return
                }
                if (getdemo.value?.trim() !== "") {
                  const params = new URLSearchParams(window.location.search)

                  params.set("email", encodeURIComponent(getdemo.value))
                  params.set("utm_source", "homepage")

                  window.location.assign(`/get-demo/?${params.toString()}`)
                } else {
                  showErrorMessage()
                }
              }}
            >
              <div className={`prediction-form-input input-wrap ${version}`}>
                <input
                  type="text"
                  name="getdemo"
                  // onFocus={e => handleInputStates(e, "focus")}
                  // onBlur={e => handleInputStates(e, "blur")}
                  onFocus={e => {
                    e.currentTarget
                      .closest(".input-wrap")
                      .classList.add("focus")
                  }}
                  onBlur={e => {
                    handleBlur(
                      e.currentTarget.closest(".input-wrap"),
                      e.currentTarget.value.trim() !== ""
                    )
                  }}
                  placeholder={placeholder}
                />
                <Button className={error !== "" ? "error" : ""} type="submit">
                  <span>Get a Demo </span>
                  {icon && (
                    <span className="icon">
                      <span className="right">{icon}</span>
                    </span>
                  )}
                </Button>
              </div>

              <span className="input-error">Please enter a valid email</span>
            </form>
          </div>
        </div>
      </div>

      <div className="prediction-image-wrapper">
        <div className="prediction-image-inner">
          <div className="top-predict-tilt-image">
            <Image
              sourceFolder={"spiralyze2024website"}
              cloudinaryPath="//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
              imgName={[
                // {
                //   imageName: "1920_1.webp",
                //   minBreakpoint: "1920px",
                // },
                {
                  imageName: "1920_1.webp",
                  minBreakpoint: "1441px",
                },
                {
                  imageName: "1440_1.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "768_1.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "360_1.webp",
                  minBreakpoint: "320px",
                },
              ]}
              fallBackImage={"1920_1.webp"}
              alt={`Top Prediction Image`}
              lazyLoad={true}
            />
            {/* <Image
                sourceFolder={"homepage"}
                imgName={`top3predictiontiltimage.png`}
                // className={"customer-logo"}
                lazyLoad={true}
                
              /> */}
          </div>
        </div>
      </div>
    </Container>
  )
}
